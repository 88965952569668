/*site colors*/
:root{
  --pwhite: #fbf9f7;
  --pwhite-50: #fbf9f7ad;
  --poffwht: #E9E2DF;
  --poffwht-50: #e9e2df6c;
  --pllbeet: #d7cbd2;
  --plbeet-50: #b1a0ab9c;
  --plbeet: #b1a0ab;
  --pbeet: #94195F;
  --pleaf: #6BAE87;
  --pleaf-25: #6bae875e;
  --pfrost: #417299;
  --pfrost-50: #4171996c;
  --pfrost-25: #41719943;
  --pgrass: #3B9277;
  --pgrass-50: #3b927779;
  --pgrass-25: #3b927744;
  --psage: #B1BCA0;
  --psage-50: #b1bca08d;
  --pmust: #B39516;
  --pmust-50: #b396167c;
  --pterra: #C45824;
  --pterra-50: #c459249b;
  --pterra-25: #c4592468;
  --ppaper: #B49575;
  --ppaper-75: #b49575cd;
  --ppaper-50: #b49575a8;
  --ppaper-25: #b4957541;
  --pcedar: #4B3A26;
  --pcedar-50: #261e14bd;
  --pdcedar: #261e14;
  --lllgrey: #f2efef;
  --llgrey: #c7c5c5;
  --lgrey: #a6a5a5;
  --lgrey-50: #a6a5a57e;
  --lgrey-25: #a4b0a649;
  --mgrey: #656363;
  --dgrey: #3d3d3d;
  --lback: rgb(34, 34, 34);
  --ltomat: rgb(255, 99, 71); /*#FF6347*/
}

/*set default base font size for em*/
html {
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  background-color: var(--poffwht);
}

h1 {
  font-family: 'Fredoka One', serif;
  font-size: 2.5em;
  font-weight: 400;
  color: black;
  margin-top: 0em;
}

h2 {
  font-family: 'Averia Serif Libre', serif;
  font-size: 1.75em;
  font-weight: 700;
  color: black;
  margin-top: 0em;
  padding-top: 1em;
}

h3 {
  font-family: 'Averia Serif Libre', serif;
  font-size: 1.4em;
  font-weight: 400;
  color: black;
  margin-top: 0em;
}

h4 {
  font-family: 'Cabin', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

h5 {
  font-family: 'Cabin', sans-serif;
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin: 0em 0em;
}

h6 {
  font-family: 'Cabin', sans-serif;
  font-size: 0.75em;
  font-weight: 400;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

p {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  font-size: 0.75em;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

ol{
  font-family:  'Averia Serif Libre', serif;
  color: var(--pbeet);
  margin: 0em 0em;
}

ol li::marker{
  font-size: 1.5em;
  font-weight: bold;
}

ol.home-text li::marker{
  font-size: 1em;
  color: var(--pbeet);
  font-weight: bold;
}

ol.home-text li{
  color: black;
  padding-bottom: 0.4em;
  text-align: left;
}

/*
a {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  font-size: 0.75em;
  text-decoration: none;
}
*/
a:link, .plink {
  text-decoration: none;
  color: var(--pbeet);
}

.footer-list li a:link {
  text-decoration: none;
  color: var(--pcedar);
}

.footer-list li a:hover {
  color: var(--pterra);
}

.plink {
  margin: none;
}

a:hover, .plink:hover {
  color: var(--pmust);
}

a:active, .plink:active {
  color: var(--ltomat);
}

tspan{
  font-family: 'Cabin', sans-serif;
  font-size: 0.85em;
  font-weight: 500;
  text-transform: capitalize;
}

/*FORMS*/
#simple{
  height: 100vh;
  position: absolute;
}

.login-form, .register-form {
  width: 22em;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.5em;
}

.login-input {
  display: flex;
  background-color: #FFF;
  color: var(--dgrey);
  border: 2px solid var(--pgrass);
  box-shadow: 0.25em 0.25em var(--poffwht);
  border-radius: 0.25em;
  padding: 0.1em 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  font-family: 'Averia Serif Libre', serif;
  font-size: 1em;
  height: 1.5em;
  width: 10em;
}

.login-label {
  max-width: 6em;
  min-width: 4em;
}

input[type=submit], input[type=button] {
  color: white;
  background-color: var(--pgrass);
  border-radius: 0.9em;
  border: 0 none;
  padding: 0.25em 0.5em;
  margin: 0.5em 0em;
  font-family: 'Fredoka One', serif;
  font-size: 1.25em;
  height: 2em;
  cursor: pointer;
  text-transform: lowercase;
  box-shadow: 0.1em 0.1em var(--lgrey-50);  
}

input[type=submit]#free-trial {
  background-color: var(--pbeet);
  box-shadow: 0.1em 0.1em var(--pwhite);  
}

input[type=button]#minor-button,  input[type=submit]#minor-button{
  color: white;
  background-color: var(--pfrost);
  padding: 0.1em 0.5em;
  margin: 0;
  font-size: 1.2em;
  height: 2em;
  cursor: pointer;
  text-transform: lowercase;
}

#login-inactive {
  background-color: var(--pwhite-50);
  border: 3px solid var(--pgrass);
  color: var(--mgrey);
}

button:active {
  background-color: var(--pmust);
}

#add {
  background-color: var(--pgrass);
  margin-right: 0.5em;
}

#cancel {
  background-color: var(--ltomat);
  margin-left: 0.5em;
}

input[type=submit]:hover, 
input[type=button]:hover, 
input#login-inactive:hover, 
input[type=button]#minor-button:hover, 
input[type=submit]#minor-button:hover,
input[type=button]#add:hover,
input[type=submit]#add:hover,
input[type=button]#cancel:hover,
input[type=submit]#free-trial:hover,
input[type=button].consent-button:hover {
  background-color: var(--pmust);
  color: black;
  box-shadow: 0.2em 0.2em var(--lgrey);
}

input[type=submit]:focus {
  background-color: var(--ltomat);
  color: black;
  box-shadow: 0.25em 0.25em var(--ppaper);
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  border: 0.125em solid var(--lgrey);
  border-radius: 15%;
  transform: translateY(0.4em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--pbeet);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid var(--pleaf);
  outline-offset: max(2px, 0.15em);
}

input[disabled]{
  background-color: var(--lgrey-50);
}

#register-btn {
  margin-left: 0.5em;
}

button[disabled].log-delete{
  background-color: var(--lgrey);
  cursor: auto;
}

.button-panel{
  margin-bottom: 0.5em;
}

.settings-input {
  display: flex;
  background-color: #FFF;
  color: var(--dgrey);
  border: 2px solid var(--pgrass);
  box-shadow: 0.25em 0.25em var(--poffwht);
  border-radius: 0.25em;
  padding: 0.1em 0.5em;
  margin-bottom: 0em;
  font-family: 'Averia Serif Libre', serif;
  font-size: 1em;
  height: 1.5em;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  border-width: thin;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 3%;
}

.acct-settings-input {
  display: flex;
  background-color: #FFF;
  color: var(--dgrey);
  border: 2px solid var(--pgrass);
  box-shadow: 0.25em 0.25em var(--poffwht);
  border-radius: 0.25em;
  padding: 0.1em 0.5em;
  margin-bottom: 0em;
  font-family: 'Averia Serif Libre', serif;
  font-size: 1em;
  height: 1.5em;
  width: 14em;
  max-width: 22em;
  min-width: 12em;
}

table {
  border: 1px dashed var(--pbeet);
  min-height: 3.5em;
}

table.prices {
  border-radius: 0.5em;
  min-width: 24em;
  max-width: 40em;
  width: 50%;
  margin-bottom: 1em;
}

table.prices tr {
  background-color: var(--pwhite-50);
  height: 3em;
  text-align: center;
}

table.prices td {
  text-align: center;
  vertical-align: text-top;
  font-size: 1.1em;
  height: 6em;
  color: var(--mgrey);
  width: 50%;
  padding-top: 0.15em;
}

table.prices .lower{
  text-transform: lowercase;
}

table.prices .aside{
  color: var(--lgrey);
  font-size: 0.9em;
  font-style: italic;
}

table.prices th {
  text-align: center;
  font-size: 1.75em;
  background-color: var(--mgrey);
  color: var(--pwhite);
}

td h2 {
  padding-bottom: 0em;
  font-size: 1.5em;
  color: var(--pgrass);
}

td span.subtitle {
  font-size: 0.8em;
  color: var(--plbeet);
  text-transform: uppercase;
}

th {
  text-transform: capitalize;
  font-family: 'Cabin', sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
  /*width: 5.75em;*/
  text-align: left;
  padding-left: 0.25em;
}

th.narrow{
  width: 1.25em;
  min-width: 1.25em;
}

tr th {
  background-color: var(--lgrey-50);
  padding-right: 0.2em;
}

tr td{
  text-transform: capitalize;
  font-family: 'Cabin', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  color: var(--dgrey);
  margin-top: 0em;
  margin-bottom: 0em;
  vertical-align: middle;
  text-align: left;
  padding-left: 0.25em;
  height: 1.25em;
  max-width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr td.table-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.form-window-max {
  max-height: 12em;
  overflow-y: scroll;
}

div.dashed-half-wd {
  border: 2px dashed var(--lgrey);
  padding: 0.25em;
  width: 40%;
  max-width: 9.5em;
  min-width: 9.25em;
}

.err-msg {
  color: var(--pbeet);
  text-align: center;
}

.hide{
  display: none;
}

/* Radio buttons */
.radio-box {
  display: inline-flex;
  position: relative;
  float: right;
  width: auto;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  border: 0.125em solid var(--lgrey);
  border-radius: 50%;
  transform: translateY(0.25em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--pbeet);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid var(--pleaf);
  outline-offset: max(2px, 0.15em);
}

/* input styling */
select {
  width: 19em;
  min-height: 2em;
}

input, select {
  cursor: pointer;
}

input[type=text].settings-input {
  min-width: 17.75em;
}

input[type=number].settings-input {
  width: 6em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.unsaved{
  display: none;
}

.saved-changes{
  padding-top: 0.35em;
}

.warning {
  color: var(--ltomat);
  align-self: flex-start;
  padding-left: 0.5em;
}

section {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

section#long-form{
  min-height: 65em;
}

.popup-form {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  min-width: 40em;
  max-width: 42em;
  margin-top:2em;
  margin-bottom: 2em;
  border: 2px solid var(--ppaper);
  border-radius: 0.5em;
  background-color: var(--pwhite);
  position: relative;
}

/*crop match dialog*/
.popup-dialog {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  width: 25em;
  min-width: 21.5em;
  border: 2px solid var(--ppaper);
  border-radius: 0.5em;
  background-color: var(--pwhite);
  position: absolute;
  top: 6em;
  right: 2em;
  z-index: 20;
}

.popup-dialog .form-section {
  margin: 0;
  padding: 0;
}

.popup-dialog .seed-info {
  font-size: 1.5em;
  width: 100%;
  overflow: ellipsis;
  text-align: center;
}

.popup-dialog #harvest-log {
  margin-bottom: 0.25em;
}

.popup-dialog .form-title{
  height: 2.5em;
}

.form-title-with-button {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 98%;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1em;
}

.form-title-with-button h1 {
  font-size: 2em;
}

.form-title-with-button input[type=button].minimize {
  color: var(--pcedar);
  background-color: var(--poffwht);
  border-radius: 0.3em;
  border: 0 none;
  font-family: 'Fredoka One', serif;
  font-size: 2em;
  height: 1em;
  line-height: 1em;
  width: 1em;
  margin-top: 0.05em;
  margin-right: 0.125em;
  padding: 0em;
  padding-right: 0.5em;
  cursor: pointer;
  box-shadow: 0.1em 0.1em var(--lgrey-50);  
}

.form-title-with-button input[type=button].minimize:hover {
  background-color: var(--pmust);
  color: black;
  box-shadow: 0.15em 0.15em var(--lgrey-50);
}

#agree-row {
  flex-flow: row nowrap;
  justify-content: left;
  padding-left: 1em;
  margin-top: 0.5em;
  max-width: 90%;
}

#agree-row p {
  text-align: left;
  width: 23em;
  padding-left: 1em;
  margin-top: 0.5em;
}

.acct-form, .onb-form {
  display: flex;
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 2em;
  flex-direction: column;
  align-items: center;
  min-width: 15em;
  max-width: 40em;
  border: 2px solid var(--ppaper);
  border-radius: 0.5em;
}

.onb-form{
  width: 30em;
  margin: 1em;
}

.acct-form .form-row {
  width: 100%;;
}

@media screen and (max-width: 980px){
  .popup-dialog {
    top: 7.5em;
    right: 0.5em;
    width: 22em;
    z-index: 20;
  }

  .popup-dialog .form-title{
    height: 2em;
  }
  
  .form-title-with-button h1 {
    font-size: 1.75em;
  }

  .form-title-with-button input[type=button].minimize {
    margin-top: 0em;
  }
}

@media screen and (max-width: 820px){
  .popup-dialog {
    position: relative;
    top: 6em;
    left: 0em;
    width: 22em;
    z-index: 20;
    margin-bottom: 1em;
    margin-left: 2em;
  }

  .popup-dialog .form-section{
    width: 95%;
  }

  .mobile-block{
    display: inline-block;
  }
}

@media screen and (max-width: 450px){
  .popup-dialog {
    position: relative;
    top: 11em;
    left: 0em;
    width: 95vw;
    margin-left: 0.25em;
  }
}

/*popup forms mobile*/
@media screen and (max-width: 450px){
  .form-section h2 {
    font-size: 1.2em;
  }

  #register.form-section {
    max-width: 90%;
  }

  #register.form-section .form-row .login-label{
    min-width: 4em;
  }

  img#hide-mobile{
    display: none;
  }

  #login.form-section {
    padding: 0;
  }

  #register.form-row{
    width: 100%;
  }

  section .popup-form{
    min-width: 24em;
    max-width: 24em;
    margin: 0;
  }

  section .popup-form#harvest-form{
    min-width: 24em;
    max-width: 24em;
    margin: 0;
  }

  .form-row label{
    width: 10em;
  }

  .form-row h3 {
    padding-top: 0.4em;
  }

  .form-section h3{
    font-size: 1em;
  }

  .form-section h4{
    padding-top: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 1em;
  }

  input[type=number].settings-input {
    width: 4em;
  }

  input[type=text].settings-input {
    min-width: 8em;
  }

  input[type=date].settings-input {
    max-width: 8em;
  }

  h4#end-word{
    padding-left: 0.5em;
    margin-left: 0em;
    width: 5em;
  }

  .harvest-end-word{
    width: 9.5em;
  }

  .form-row #harden-off-lbl{
    padding-left: 0;
    width: 10em;
  }

  select.settings-input{
    width: 11.25em;
  }

  input.settings-input{
    width: 10em;
  }

  select.acct-settings-input {
    max-width: 9em;
    min-width: 9em;
  }

  input.acct-settings-input {
    max-width: 9em;
    min-width: 9em;
  }

  .form-row label.acct-label{
    min-width: 8em;
    width: 8em;
  }

  .form-row label.acct-pref{
    min-width: 14em;
    width: 14em;
  }
}

.form-title {
  background-color: var(--pbeet);
  border-radius: 0.3em;
  width: 100%;
  height: 3em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 0em;
  margin-bottom: 1em;
}

.form-title h1 {
  color: var(--pwhite);
  padding-left: 0.25em;
  text-transform: capitalize;
}

.form-section {
  display: flex;
  flex-flow: column;
  width: 90%;
  /*border: 2px dashed var(--lgrey);*/
  padding: 0.75em 1em;
  margin-bottom: 0.75em;
  gap: 0.75em 0.5em;
  align-items: center;
  justify-content: space-between;
}

#register.form-section .login-label{
  min-width: 8em;
}

#register.form-section{
  padding: 0em;
  margin: 0em;
}

#login, #register {
  width: 20em;
  justify-content: center;
  padding-right: 0em;
  margin-right: 0em;
}

#register-prompt {
  width: 80%;
  justify-content: center;
  margin-top: 1em;
  border-top: 2px dashed var(--mgrey);
}

.register-motto {
  width: 16em;
  text-align: center;
  padding: 0;
  margin: 0;
}

h3.motto {
  text-align: center;
}

.register-panel{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 8em;
  padding-top: 0.5em;
  margin-bottom: 0em;
  padding-bottom: 0em;
}

.form-section h4{
  margin-top: 0.125em;
  margin-left: 0em;
  color: var(--mgrey);
}

.form-row {
  min-width: 19em;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  position: relative;
}

.home-form-row {
  min-width: 10em;
  max-width: 28em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
}

.home-form-col {
  width: 14em;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: column;
  text-align: center;
}

.home-form-row input#email {
  margin-top: 0.65em;
  margin-right: 1em;
}

label h6{
  padding-left: 0.5em;
}

label {
  display: flex;
  width: 16em;
  min-height: 2em;
}

.main-logo h6 {
  width: 50%;
  color: var(--dgrey);
  text-align: center;
}

.plannt {
  font-family: 'Fredoka One', serif;
  display: inline;
  font-size: 1em;
  font-weight: 400;
  color: var(--dgrey);
  margin-top: 0em;
}

.lighter {
  color: var(--pwhite);
}

.guess {
  display: inline-block;
  font-weight: 700;
  margin-top: 0em;
  color: var(--pleaf);
  /* -webkit-text-fill-color: none;
  -webkit-text-stroke: 1px var(--pwhite); */
}

input::placeholder {
  color: var(--lgrey);
}

/*ICON*/
.icon {
  width: auto;
  height: 100%;
  overflow: hidden;
}

a .icon:hover {
  cursor: pointer;
}

.icon img {
  max-width: 100%;
  height: auto;
  display: block;
}

.full-page {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0em;
  left: 0em;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}

#full-view.full-page{
  /*padding: 0;*/
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.home-page{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.home-page::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.socials{
  width: 35px;
  height: 35px;
  padding-left: 0.4em;
  padding-right: 0.4em;
}

.veg-icons{
  width: 50px;
  height: 50px;
  padding-bottom: 1em;
  padding-top: 0.75em;
}

#demo {
  display: flex;
  flex-direction: column;
}

#youtube {
  max-width: 90vw;
}

#subtext{
  color: var(--mgrey);
}

.top-bar{
  height: 6.5em;
  width: 100%;
  background-color: var(--poffwht);
  display: flex;
  flex-flow: row;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}

.top-container{
  width: 96%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10;
}

.left-top-container{
  width: auto;
  /*min-width: 20em;*/
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
}

.top-menu-button{
  height: 2.5em;
  min-width: 8.5em;
  background-color: var(--lgrey-50);
  border-radius: 1em;
  margin-left: 0.5em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.top-menu-button a{
  height: 100%;
  width: 100%;
}

.blog-btn{
  padding-top: 0.25em;
}

#cta-blog{
  text-align: center;
  width: 100%;
  margin-bottom: 2em;
  margin-right: 0em;
}

.shift-down{
  padding-top: 5.5em;
}

.top-menu-burger{
  height: 45%;
  width: auto;
  background-color: var(--poffwht);
  border: 0 none;
  margin-left: 0.5em;
  padding: 0.5em 0.5em;
  border-radius: 1em;
  display: none;
}

.top-menu-burger:hover{
  background-color: var(--pgrass-25);
}

.top-menu-burger img{
  height: 100%;
  width: auto;
}

.top-menu-panel, .lower-menu-panel{
  display: none;
  padding-inline-start: 0;
}

.top-menu-panel.expanded{
  width: 20em;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.75em;
}

.lower-menu-panel.expanded{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 6em;
  left: 37em;
  width: 17em;
  height: auto;
  background-color: var(--poffwht);
  border-radius: 0 0 1em 1em;
  padding-bottom: 0.25em;
}

.lower-menu-panel .top-menu-button{
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

#mobile-hide:hover{
  cursor: pointer;
}

/*home screen for mobile*/
@media screen and (max-width: 980px){
  #mobile-hide{
    display: none;
  }

  .top-menu-burger{
    display: block;
  }
  
  .top-menu-panel {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 6em;
    left: 8em;
    width: 14.5em;
    height: auto;
    background-color: var(--poffwht);
    border-radius: 0 0 1em 1em;
    padding-bottom: 0.25em;
  }

  .top-menu-panel .top-menu-button{
    margin-bottom: 0.5em;
    margin-right: 0.75em;
  }
  
  .top-menu-panel.expanded{
    display: none;
  }

  .lower-menu-panel{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0em;
    left: 0em;
    height: auto;
    padding-bottom: 0.25em;
  }

  li.top-menu-button span {
    font-size: 1.4em;
  }

  table.prices {
    min-width: 22em;
  }

  table.prices span.aside {
    font-size: 0.6em;
  }
}

.hb-menu-button{
  background-color: inherit;
  border: none;
  height: 3em;
  width: 3em;
  padding: 0em;
  margin: 0em;
}

.hb-menu-button img{
  width: 100%;
  height: 100%;
}

.top-menu-button:hover {
  background-color: var(--pmust-50);
}

.top-button-selected{
  background-color: var(--pgrass-50);
}

.top-menu-button span{
  font-family: 'Averia Serif Libre', serif;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  text-align: center;
  font-weight: 600;
}

.dropdown{
  width: 15%;
  height: auto;
  margin-right: 0.5em;
}

.logo-text-home {
  font-size: 1.5em;
  padding-top: 0em;
  margin-top: 0em;
}

.home-button-panel{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 11em;
  padding-right: 0.5em;
}

.home-info-panel {
  padding-top: 6em;
  background-image: linear-gradient(to bottom, var(--poffwht), var(--pwhite));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  height: 100%;
}

.pricing-preamble{
  font-size: 1.5em;
  padding-bottom: 0.5em;
  text-align: center;
  margin: 0 1em;
  max-width: 40em;
}

.trial-text{
  font-family: 'Cabin', sans-serif;
  width: auto;
  color: var(--pwhite);
  font-size: 1em;
  padding-top: 0em;
  margin-top: 0em;
  font-weight: 600;
  padding-top: 0.1em;
  padding-left: 0.8em;
}

.cents{
  font-size: 60%;
}

.footer-span{
  width: 100%;
  min-height: 3em;
  height: auto;
  background-color: var(--psage);
  overflow: hidden;
  position: relative;
  left: 0;
  bottom: 0;
}

.footer-panel{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 0.5em;
  gap: 0.5em 0em;
}

.footer-list{
  list-style-type: none;
}

#icon-row {
  display: flex;
  flex-direction: row;
  gap: 0em 1em;
  margin: 0;
  padding-left: 1em;
  max-width: 14em;
}

#word-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row nowrap;
  min-width: 20em;
  justify-content: space-around;
  align-items: center;
}

.footer-list li{
  font-family: 'Averia Serif Libre', serif;
  font-size: 1em;
  font-weight: 500;
  color: var(--dgrey);
  margin: 0em;
  padding: 0em;
}

video{
  z-index: 1;
  width: 100vw;
  max-width: 35em;
}

#band{
  background-color: var(--plbeet-50);
  display: flex;
  align-items: center;
}

#pricing-row{
  background-color: var(--ppaper-25);
  display: flex;
  align-items: center;
}

.home-row{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
}

.home-text-row{
  padding-bottom: 0.75em;
}

.home-text{
  padding: 1em;
  max-width: 50em;
}

#faq-row{
  height: 26em;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}

#about-row .side-info{
  padding-top: 3em;
}

.home-col{
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  margin-top: 0.5em;
}

.wide-img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.main-message{
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background-color: var(--plbeet-50);
  justify-content: space-between;
  overflow: hidden;
}

.home-end{
  width: 100%;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0em;
}

.top-banner-left{
  min-width: 20em;
  max-width: 90%;
  margin-left: 5%;
  padding: 2em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.top-banner-right{
  display: flex;
  flex-direction: row;
  width: 42%;
  margin-right: 0em;
  justify-content: flex-end;
  overflow: hidden;
}

.video-overlay{
  position: absolute;
  min-width: 20em;
  max-width: 90%;
  margin-left: 5%;
  padding: 2em 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  z-index: 5;
}

#fullscreen-video{
  object-fit: cover;
  max-width: 100%;
  min-height: 450px;
  z-index: 1;
  pointer-events: none;
}

.layer-cont{
  background-color: var(--pdcedar);
  position: relative;
  width: 100%;
  height: 55vw; /*1920 x 1080 aspect ratio*/
  overflow: hidden;
}

.wide-left{
  border: 10px solid var(--psage-50);/*hsla(0,0%,100%,.5);*/
  background-color: var(--pwhite);
  border-radius: 1em;
  min-width: 18em;
  width: 45em;
  min-height: 15em;
  height: 25em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.side-info{
  width: 100%;
  height: 100%;
  max-height: 24em;
  padding: 1.5em;
  overflow-y: hidden;
  flex-shrink: 1;
}

.narrow-right{
  min-width: 6em;
  max-width: 24em;
  margin-right: 1em;
  margin-top: 0.5em;
  margin-left: 1em;
  padding-left: 0em;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.spread-img{
  object-fit: cover;
  min-width: 20em;
  height: 100%;
  overflow: hidden;
}

.squash-img{
  object-fit: cover;
  min-width: 0em;
  max-width: 35em;
  height: 100%;
  overflow: hidden;
  align-self: flex-end;
}

.demo-img{
  min-width: 10em;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1000px){
  img.squash-img{
    display: none;
  }

  img.demo-img{
    object-fit: cover;
    object-position: 50% 50%;
  }

  #seed-img-left, #task-img-left, #yard-img-left{
    object-position: 0% 0%;
  }

  #analytics-img-left{
    object-position: 45% 0%;
  }
}

.shd{
  background-color: var(--ppaper-25);
  padding-right: 0;
  margin-right: 0;
  justify-content: space-between;
}

#starter{
  margin-top: 4em;
}

.step{
  width: 100%;
  color: var(--pbeet);
  font-size: 2em;
}

.step-blurb{
  max-width: 22em;
  font-family: 'Cabin', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

.step-img{
  width: auto;
  height: 4em;
}

/*highlight reel*/
.home-grid {
  display: flex;
  gap: 2em 3em;
  width: 90%;
  margin: 0 auto;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.highlight-box {
  background-color: var(--pwhite-50);
  border: 2px solid var(--lgrey-50);
  border-radius: 1em;
  width: 20em;
  height: 22em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  box-shadow: 8px 8px 2px var(--ppaper-50);
  overflow: hidden;
}

.highlight-pic {
  margin-top: auto;
  width: 100%;
  height: 65%;
  overflow: hidden;
}

.highlight-pic img{
  width: 100%;
  height: auto;
  margin-bottom: 0%;
}

.highlight-box h3 {
  width: 95%;
  text-align: center;
  color: var(--lback);
  font-size: 2em;
  padding: 0.25em 0em 0em 0em;
  margin-bottom: 0.25em;
}

.highlight-box h4 {
  width: 90%;
  color: var(--dgrey);
  text-align: center;
  padding: 0em 1em 0em 1em;
}

/*about*/
.about-box {  
  width: 20em;
  height: 24em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  overflow: hidden;
}

.about-box h3 {
  text-align: center;
  padding-top: 1em;
}

.about-box h4 {
  width: 90%;
  color: var(--mgrey);
  font-size: 1em;
  text-align: center;
  padding: 0em 1em 0em 1em;
}

.about-pic {
  border-radius: 50%;
  margin-top: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.about-pic img{
  width: 100%;
  height: auto;
  margin-bottom: 50%;
  /*transform: translateY(-28%);*/
}

.logo-panel {
  /*background-image: linear-gradient(to bottom, var(--poffwht-50), var(--pwhite));*/
  background-color: var(--poffwht-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-top: 0em;
  width: 100%;
  /*padding: 2em 2em;*/
  height: 100%;
}

.logo-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.25em;
  height: 8em;
  min-height: 6em;
}

.logo-compact h1{
  font-size: 2em;
  padding-bottom: 0em;
  margin-bottom: 0.5em;
}

.big-motto{
  font-size: 3.25em;
  line-height: 1em;
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: 'Averia Serif Libre', serif;
  font-weight: 400;
  color: var(--pwhite);
}

.home-blurb h2{
  font-family: 'Cabin', sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  color: var(--pwhite);
  margin-top: 0em;
  margin-bottom: 0em;
}

.text-bg {
  padding: 0.25em 0.5em;
  background-color: var(--pcedar-50);
  border-radius: 0em 0.75em 0.75em 0em;
}

.shift-right{
  text-align: right;
  padding-right: 20%;
  padding-bottom: 0.5em;
}

.separation-word{
  padding-top: 2.5em;
  padding-bottom: 0em;
  color: var(--pbeet);
  font-size: 2.5em;
  text-align: center;
}

.right-panel{
  background-color: var(--psage);
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  width: 80em; /*was 50em*/
  margin-top: 0em;
}

.login {
  background-color: var(--pwhite-50);
  border: 2px solid var(--pbeet);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 24em;
  max-width: 92%;
  height: auto;
  border-radius: 0.5em;
  box-shadow: 0.5em 0.5em var(--plbeet);
  margin-bottom: 3em;
  padding: 0em;
}

.register {
  background-color: var(--pwhite-50);
  border: 2px solid var(--pbeet);
  display: flex;
  flex-direction: column;
  align-items: center; /*left with offset?*/
  justify-content: top;
  width: 24em;
  max-width: 93%;
  height: auto;
  border-radius: 0.5em;
  box-shadow: 0.5em 0.5em var(--plbeet);
  padding: 0em;
  margin-bottom: 3em;
}

/* NAVBAR */
.icon-top-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 7.5em;
  padding-top: 1.5em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
}

#topleft-icon {
  margin-top: 0.25em;
  min-height: 3.5em;
}

#bottom-icon {
  min-height: 6.5em;
}

.tm-box{
  position: relative;
}

.TM {
  position: absolute;
  z-index: 5;
  right: -1em;
  top: 0.25em;
  font-family: arial;
  font-size: 0.5em;
  color: var(--lgrey)
}

#logout {
  cursor: pointer;
  border: none;
  background-color: var(--poffwht);
  width: 100%;
  padding: 0; 
  height: 3.25em;
}

#logout-text {
  font-size: 2em;
  text-align: left;
  padding-top: 0.1em;
  padding-left: 0.1em;
}

#pricing-title{
  max-width: 11.25em;
}

/*nav for mobile*/
@media screen and (max-width: 1015px){
  .home-blurb h2{
    font-size: 1.2em;
  }

  h1.big-motto{
    font-size: 2.1em;
    margin-bottom: 0.5em;
    margin-top: 0;
  }

  .trial-text{
    font-size: 0.8em;
  }

  h1#faq-title{
    max-width: 13em;
  }

  h1#pricing-title{
    max-width: 11.25em;
  }

  div.layer-cont{
    height: 28.25em; /*1920 x 1080 aspect ratio*/
    overflow: hidden;
  }
}

@media screen and (max-width: 450px){
  div.icon-top-left {
    padding-left: 0em;
  }

  .full-page{
    flex-direction: column;
    padding-top: 5em;
    overflow: auto;
  }

  /*demo screenshots*/
  .wide-left{
    height: 17em;
    width: auto;
  }

  p.home-text{
    padding: 0.5em;
  }

  .home-blurb h2, h1.big-motto {
    width: 81vw;
  }

  div.main-page{
    width: 100%;
  }
}

.main-page{
  background-color: var(--pwhite);
  display: flex;
  flex-direction: column;
  align-items: left;
  align-self: stretch;
  justify-content: top;
  width: 88%;
}

.page-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  max-width: 110em;
  /*border: 2px solid salmon;*/
  min-height: 5.5em;
  background-color: var(--lllgrey);
  overflow-y: hidden;
  overflow-x: hidden;/*scroll;*/
}

#scroll-header{
  overflow-x: scroll;
  min-height: 6em;
}

.page-header h2 {
  padding-left: 0.4em;
  color: var(--dgrey);
}

.header-icon {
  height: 2em;
  width: auto;
}

.header-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /*border: 2px solid black;*/
  width: 11em;
  min-height: 3em;
  margin-left: 0.5em;
}

.page-container{
  width: 100%;
  overflow-y: scroll;
}

.page-content {
  margin-left: 2em;
  margin-right: 2em;
  min-height: 42em;
}

/*seeds for mobile*/
@media screen and (max-width: 450px){
  div.main-page{
    height: 100%;
    min-height: 50em;
  }

  .page-content{
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 2em;
  }

  section#long-form{
    width: 100vw;
    min-height: 58em;
  }

  div#scroll-header.page-header{
    position: fixed;
    top: 4.5em;
    z-index: 4;
  }

  div.help-tip p{    /* The tooltip */
    width: 10em;
    left: -8em;
  }

  div.help-tip p:before{ /* The pointer of the tooltip */
    left:8.4em;
    top:-12px;
  }

  div.help-tip-rating p{
    left: 0em;
    margin-right: 0.5em;
  }

  div.help-tip-rating p:before{
    left: 0.35em;
    margin-right: 0.5em;
  }
}

.page-footer{
  position: fixed;
  bottom: 0.5em;
  right: 1em;
  background-color: var(--pwhite-50);
}

.footer-buffer{
  min-height: 3em;
}

.copyright-msg{
  font-size: 1.1em;
  padding-bottom: 0.25em;
}

.copyright-msg p {
  text-align: center;
  color: var(--mgrey);
}

.subheader {
  min-height: 3.5em;
}

.visible{
  visibility: visible;
}

.hidden{
  display: none;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 0.6em solid transparent;
  border-bottom: 0.6em solid transparent; 
  border-right: 0.6em solid var(--pbeet); 
  z-index: 5;
}

.plural {
  display: inline-block;
}

.non-plural {
  display: none;
}

.capitalize{
  text-transform: capitalize;
}

/*forms for mobile*/
@media screen and (max-width: 450px){
  h2.header-word{
    padding: 0;
    width: 100%;
    text-align: center;
  }

  span.sup{
    justify-content: flex-end;
    width: 0.65em;
  }

  details h4 div.post-body{
    width: 95%;
  }
}

/*FAQs*/
.faqs-list{
  margin-top: 0.5em;
  min-width: 32em;
  max-width: 60em;
  display: flex;
  flex-flow: column nowrap;
  align-items: left;
  gap: 0.5em 0em;
  padding-left: 1em;
  padding-top: 1em;
  padding-bottom: 3em;
}

details summary{
  cursor: pointer;
}

summary h3 {
  color: var(--dgrey);
  padding-bottom: 0.75em;
  font-size: 1.25em;
}

details h4 {
  margin-left: 0.8em;
  padding-top: 0.5em;
  color: var(--pbeet);
}

details summary > * {
  display: inline;
}

details{
  padding-left: 0.8em;
  padding-bottom: 1em;
  max-width: 50em;
}

.header-word {
  font-size: 2em;
  padding-top: 0.75em;
}

#header-status {
  width: 5em;
}

.header-msg {
  padding-left: 0.75em;
  padding-top: 1em;
  font-style: italic;
  color: var(--mgrey);
}

.italic {
  font-style: italic;
}

address h5{
  font-style: normal;
}

.sup {
  position: relative;
  bottom: 0.5em; 
  font-size: 80%;
}

.area-chart{
  overflow-y: scroll;
}

#faq{
  overflow: hidden;
}

.faq-blurb{
  width: 100%;
  text-align: center;
  line-height: 3em;
  font-size: 2em;
}

.post-title {
  font-size: 1.6em;
  width: 100%;
  color: var(--pcedar);
  margin-top: 0.5em;
  text-align: left;
}

.post-date{
  color: var(--pgrass);
  font-size: 1em;
}

.posts-container {
  width: 100vw;
  max-width: 60em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.faq-post{
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 1em;
  margin-top: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--ppaper-50);
  box-shadow: 6px 6px 2px var(--ppaper-25);
  border-radius: 0.7em;
  background-color: var(--pwhite);
  overflow: hidden;
}

.post-body {
  font-family: 'Averia Serif Libre', serif;
  font-size: 1.2em;
  font-weight: 500;
  width: 100%;
  margin-left: 0em;
  padding-left: 0em;
  padding-top: 0.4em;
  color: var(--pbeet);
}

.post-body ol li, .post-body ul li {
  font-family: 'Averia Serif Libre', serif;
  font-size: 1em;
  font-weight: 500;
  width: 100%;
  margin-left: 0em;
  padding-left: 0em;
  margin-bottom: 0.25em;
  color: var(--pbeet);
  text-align: left;
}

.post-body ol li::marker {
  font-size: 1.2em;
}

.post-body a{
  color: var(--pfrost);
}

.post-body a:hover{
  color: var(--pmust);
}

/*TOOLTIP*/
/*this item's parent container needs position: relative*/
.help-tip{ /*the question mark*/
  position: relative;
  top: 0.2em;
  left: 12em;
  text-align: center;
  background-color: var(--ppaper-50);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  font-size: 1em;
  line-height: 1.5em;
  cursor: default;
}

.help-tip:before{
  content:'?';
  font-weight: bold;
  color:#fff;
}

.help-tip:hover p{
  display:block;
  transform-origin: 100% 0%;

  -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  animation: 1s ease 0s normal forwards 1 fadeIn;
}

.help-tip p{    /* The tooltip */
  display: none;
  text-align: left;
  background-color: var(--dgrey);
  padding: 0.5em;
  width: 10em;
  position: absolute;
  border-radius: 3%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  left: -0.25em;
  color: #FFF;
  font-size: 1em;
  line-height: 1.4;
  z-index: 5;
}

.help-tip p:before{ /* The pointer of the tooltip */
  position: absolute;
  content: '';
  width:0;
  height: 0;
  border:6px solid transparent;
  border-bottom-color:var(--dgrey);
  left:10px;
  top:-12px;
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% { opacity:0; }
  80% { opacity:0; }
  100% {opacity:100%; }
}

@keyframes fadeIn {
  0% { opacity:0; }
  80% { opacity:0; }
  100% { opacity:100%; }
}

.arrow-right {
  position: absolute;
  right: 4.5em;
  width: 0; 
  height: 0; 
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-left: 0.5em solid var(--dgrey);
}

/*LEGAL*/
.policy {
  padding: 2em;
  width: 80%;
  max-width: 60em;
}

.policy-header {
  text-align: center;
}

.policy-content ol, li, h5 {
  color: var(--dgrey);
  font-family: 'Cabin', sans-serif;
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
}

ul.about-content li {
  font-family: 'Averia Serif Libre', serif;
  font-weight: normal;
  color: black;
  text-align: left;
  padding-bottom: 0.25em;
}

ol.about-content li {
  font-family: 'Averia Serif Libre', serif;
  font-weight: normal;
  color: black;
  text-align: left;
  padding-bottom: 0.25em;
}

ol.about-content li::marker {
  font-size: 1.2em;
}

input[type=textarea] {
  width: 18.5em;
  height: 8em;
}

.contact-col {
  display:flex;
  flex-direction:column;
  align-items:center;
  margin:0;
  width: 100%;
  padding-bottom:1em;
}

#email-wide{
  width: 91%;
}

.contact-title{
  width:100%;
  text-align:center;
  font-weight: 600;
}

.contact-info{
  width:100%;
  text-align:center;
  padding-bottom:1.5em;
  color: var(--mgrey);
}

.req-field{
  color: var(--pbeet);
}

span.b {
  font-weight: 700;
  color: black;
}

span.u {
  text-decoration: underline;
}

.policy-content li::marker{
  font-size: 1.25em;
  color: var(--pbeet);
}

.policy-content h4{
  padding-top: 0.5em;
  text-align: left;
}

.policy-content{
  margin-bottom: 3em;
}

section.policy-cont{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.policy-content li{
  margin-bottom: 0.5em;
}

.article{
  padding-bottom: 0.5em;
}

ol.toplist {
  list-style-type: none;
  padding: 0;
}

ol.toplist li {
  counter-increment: section;
}

ol.points {
  list-style-type: none;
  padding: 0;
}

ol.points li:before {
  content:counter(section) "." counter(item) " ";
  font-weight: bold;
  font-size: 1.1em;
  color: var(--pbeet);
}

ol.points li { 
  counter-increment: item;
}

ol.brackets {
  list-style-type: none;
}

ol.brackets li:before {
  content: "(" counter(subsection, number) ") ";
  font-weight: bold;
  font-size: 1.1em;
  color: var(--pbeet);
}

ol.brackets li { 
  counter-increment: subsection;
}

ol.brackets-alpha{
  list-style-type: none;
}

ol.brackets-alpha li:before {
  content: "(" counter(list-item, lower-alpha) ") ";
  font-weight: bold;
  font-size: 1.1em;
  color: var(--pbeet);
}

ol.brackets-alpha li{
  counter-increment: list-item;
}

@media screen and (max-width:450px){
  .policy {
    padding: 1em;
    width: 90%;
    min-width: 10em;
  }

  .policy-content li, h5{
    text-align: left;
  }

  .agree-form h1{
    font-size: 2em;
  }

  #center-text{
    text-align: center;
  }

  form.calculator {
    width: 31em;
  }
}

@media screen and (max-width:920px){
  div#calculator{
    justify-content: center;
  }

  div#slider-row{
    justify-content: center;
  }

  div#calculator label.calc-label h4{
    width: 100%;
    text-align: center;
  }

  #veg-icon-row{
    display: none;
  }
}

h4.beet {
  color: var(--pbeet);
}

/*spacing calculator*/
.soil-patch{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  justify-content: center;
  background-color: var(--ppaper);
  min-width: 24em;
  width: 24em;
  height: 24em;
}

.calculator {
  width: 33.5em;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

#calculator {
  justify-content: flex-start;
  max-width: 100vw;
}

#calculator .form-row {
  width: 95%;
}

#calculator label h3 {
  min-width: 24em;
  margin-bottom: 0.5em;
}

#calculator label h4 {
  margin-bottom: 0.75em;
  padding: 0;
  width: 12em;
}

form.calculator .form-section {
  padding: 0;
  margin: 0;
}

label.calc-label {
  min-width: 11em;
}

.plant-dot {
  height: 1em;
  width: 1em;
  background-color: var(--pgrass);
  border-radius: 50%;
  /* position: absolute; */
}

.first-dot-bg {
  background-color: var(--ppaper);
  padding: 0.25em;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  left: 0.15em;
  z-index: 0;
  height: 1.5em;
  width: 11.5em;
  border-radius: 3%;
  box-shadow: 1px 1px 1px var(--mgrey), 0px 0px 1px var(--dgrey);
}

#first-dot{
  z-index: 0;
  margin-left: 0em;
  height: 1em;
  width: 1em;
}

#spacing-slider::-webkit-slider-thumb{
  background-color: var(--pbeet);
  height: 1.2em;
  width: 1.2em;
  z-index: 3;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 14em;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
  background-color: var(--pbeet);
  background: transparent;
  cursor: pointer;
  margin-top: 0.35em; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  z-index: 3;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: none;
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
  background: transparent;
  background-color: var(--pbeet);
  cursor: pointer;
  z-index: 3;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: none;
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
  background: transparent;
  background-color: var(--pbeet);
  cursor: pointer;
  z-index: 3;
}

/*TRACK*/
input[type=range]::-webkit-slider-runnable-track {
  width: 14em;
  height: 2em;
  cursor: pointer;
  background: var(--ppaper);
  border: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--ppaper);
}

input[type=range]::-moz-range-track {
  width: 14em;
  height: 2em;
  cursor: pointer;
  background: var(--ppaper);
}

input[type=range]::-ms-track {
  width: 14em;
  height: 2em;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: var(--ppaper);
}

input[type=range]:focus::-ms-fill-lower {
  background: var(--ppaper);
}

input[type=range]::-ms-fill-upper {
  background: var(--ppaper);
}

input[type=range]:focus::-ms-fill-upper {
  background: var(--ppaper);
}